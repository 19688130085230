/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { VoiceProvider } from "../contexts";
import styled from "styled-components";

const Main = styled.main`
  ${({ flex }) =>
    flex &&
    `
flex:1
`}
`;
const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;
const Layout = ({ flex, children }) => {
  return (
    <VoiceProvider>
      <Container>
        <Main flex={flex}>{children}</Main>
        <footer
          style={{
            fontFamily: `'Barlow', sans-serif`,
            padding: 10,
            color: "#333",
            fontWeight: 600,
            marginTop: 30,
            textAlign: "center",
            background: "rgba(0, 0, 0, 0.05)",
          }}
        >
          © {new Date().getFullYear()} All rights reserved
        </footer>
      </Container>
    </VoiceProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

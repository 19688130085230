import React from "react";

export const VoiceContext = React.createContext();
export const useVoiceContext = () => {
  const ctx = React.useContext(VoiceContext);
  return ctx;
};

export const VoiceProvider = ({ children }) => {
  const synth = typeof window !== "undefined" && window.speechSynthesis;
  const [voices, setVoices] = React.useState([]);

  const synthVoices = synth && synth.getVoices();

  React.useEffect(() => {
    if (!voices.length && !!synthVoices.length) {
      setVoices(synthVoices);
    }
  }, [voices, synthVoices]);

  return (
    <VoiceContext.Provider value={{ voices }}>{children}</VoiceContext.Provider>
  );
};
